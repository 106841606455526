<template>
  <div>
    <div v-if="loading" class="text-center my-5">
      <b-spinner label="Loading..."></b-spinner>
      <p class="mt-2">Loading waiver status...</p>
    </div>

    <div v-else>
      <b-card class="mb-4" no-body>
        <b-card-header class="bg-primary text-white">
          <h5 class="mb-0">Groundwater Source Monitoring Waiver Status</h5>
        </b-card-header>

        <b-card-body>
          <div class="row mb-3">
            <div class="col-md-6">
              <b-form-group label="Water System:" label-class="font-weight-bold">
                <b-form-input :value="pws_id + ' - ' + pws_name" readonly></b-form-input>
              </b-form-group>
            </div>
          </div>

          <b-alert :variant="statusVariant" show>
            <div class="d-flex align-items-center">
              <b-icon v-if="waiver_status === 'Qualified'" icon="check-circle-fill" class="mr-2"></b-icon>
              <b-icon v-else-if="waiver_status === 'Not Qualified'" icon="x-circle-fill" class="mr-2"></b-icon>
              <b-icon v-else icon="exclamation-circle-fill" class="mr-2"></b-icon>
              <span class="font-weight-bold">Status: {{ waiver_status }}</span>
            </div>

            <div v-if="waiver_status === 'Not Completed'" class="mt-2">
              <p>Your water system needs to complete the monitoring waiver application.</p>
              <b-button variant="primary" @click="$router.push({ name: 'MonitoringWaiver' })">
                Complete Waiver Application
              </b-button>
            </div>

            <div v-else-if="waiver_status === 'Qualified'" class="mt-2">
              <p>Your water system is eligible for a monitoring schedule reduction for the Inorganic analyte group and
                Cyanide.</p>
              <p v-if="waiver_date" class="mb-0">
                <strong>Waiver Submission Date:</strong> {{ formatDate(waiver_date) }}
              </p>
            </div>

            <div v-else-if="waiver_status === 'Pending'" class="mt-2">
              <p>Your waiver application is currently under review. We will complete our review and offer a response by
                August 31, 2025.</p>
              <p v-if="waiver_date" class="mb-0">
                <strong>Waiver Submission Date:</strong> {{ formatDate(waiver_date) }}
              </p>
            </div>

            <div v-else class="mt-2">
              <p>Your water system is not eligible for a monitoring schedule reduction at this time.</p>
              <p v-if="ineligible_reason" class="mb-0">
                <strong>Reason:</strong> {{ ineligible_reason }}
              </p>
            </div>
          </b-alert>

          <div v-if="waiver_status !== 'Not Completed' && waiver_date" class="mt-4">
            <h5 class="text-primary mb-3">Waiver Details</h5>
            <b-row class="mb-2">
              <b-col cols="3" class="font-weight-bold">Submission Date:</b-col>
              <b-col>{{ formatDate(waiver_date) }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col cols="3" class="font-weight-bold">Submitted By:</b-col>
              <b-col>{{ waiver_submitter || 'Unknown' }}</b-col>
            </b-row>
            <b-row v-if="proposed_schedule" class="mb-2">
              <b-col cols="3" class="font-weight-bold">Proposed Schedule:</b-col>
              <b-col>{{ proposed_schedule }}</b-col>
            </b-row>
            <b-row v-if="receipt_uuid" class="mt-3">
              <b-col>
                <b-button class="mb-2" variant="primary" @click="printReceipt">Print Waiver Receipt</b-button>
                <iframe id="receiptFrame" :src="`/receipts/${receipt_uuid}`" width="100%" height="600"></iframe>
              </b-col>
            </b-row>
          </div>

          <h5 class="text-primary mb-3 mt-4">Frequently Asked Questions</h5>
          <b-card no-body class="mb-3">
            <b-tabs card vertical>
              <b-tab title="What contaminants are covered?">
                <p>Your waiver applies to all inorganic contaminants and cyanide listed at the bottom of the eligibility
                  waiver form.</p>
              </b-tab>
              <b-tab title="When does this waiver begin?">
                <p>The waiver covers the period from 2026 to 2031. If the waiver is granted, one sample per entry point
                  will be required during this time.</p>
              </b-tab>
              <b-tab title="How long is the waiver valid?">
                <p>The waiver is valid for six years. Sample results and conditions can affect eligibility. If
                  conditions change, the system should notify Water Supply.</p>
              </b-tab>
              <b-tab title="What documentation determined eligibility?">
                <p>Your waiver was granted based on historical monitoring data, risk assessments, and source water
                  protection measures. If you would like to review the specific documentation used, please contact us.
                </p>
              </b-tab>
              <b-tab title="Are there ongoing requirements?">
                <p>While your system has been granted a waiver for specific contaminants, systems are required to
                  monitor for all other regulated contaminants. Please review your compliance schedule to ensure
                  continued adherence to regulations.</p>
              </b-tab>
              <b-tab title="Can the waiver be revoked?">
                <p>Yes, the waiver may be revoked if conditions change, such as new contamination risks, regulatory
                  updates, or failure to maintain eligibility requirements. We recommend staying informed about any
                  updates that may affect your system.</p>
              </b-tab>
              <b-tab title="Does this affect other regulations?">
                <p>This waiver applies only to the specified contaminants and does not exempt your system from other
                  monitoring or regulatory requirements. Please ensure you remain in compliance with all applicable
                  regulations.</p>
              </b-tab>
              <b-tab title="How do other wells become eligible?">
                <p>If you have wells that are not listed, please indicate them on the form, and we will review their
                  status. If the sampling history is satisfactory, the well may be granted a waiver.</p>
              </b-tab>
              <b-tab title="Will I need to reapply?">
                <p>Yes, most likely in 2031 to establish eligibility for the 2032–2037 monitoring period.</p>
              </b-tab>
              <b-tab title="What if I don't comply?">
                <p>Failure to complete the waiver process means your system will remain on the current monitoring
                  schedule of one sample per three years. This is not a violation; it simply means you will be sampling
                  more frequently than required.</p>
              </b-tab>
            </b-tabs>
          </b-card>

          <h5 class="text-primary mb-3 mt-4">Help or Questions?</h5>
          <p>
            If you have questions about your waiver status or need assistance, contact:
          </p>
          <ul class="list-unstyled ml-3">
            <li><strong>Quinton Taylor</strong></li>
            <li><b-icon icon="telephone-fill" class="mr-2"></b-icon><strong>Phone:</strong> <a
                href="tel:6015767096">601-576-7096</a></li>
            <li><b-icon icon="envelope-fill" class="mr-2"></b-icon><strong>Email:</strong> <a
                href="mailto:quinton.taylor@msdh.ms.gov">quinton.taylor@msdh.ms.gov</a></li>
          </ul>
          <ul class="list-unstyled ml-3 mt-3">
            <li><strong>Thomas "Chip" Long</strong></li>
            <li><b-icon icon="telephone-fill" class="mr-2"></b-icon><strong>Phone:</strong> <a
                href="tel:6015767514">601-576-7514</a></li>
            <li><b-icon icon="envelope-fill" class="mr-2"></b-icon><strong>Email:</strong> <a
                href="mailto:thomas.long@msdh.ms.gov">thomas.long@msdh.ms.gov</a></li>
          </ul>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringWaiverStatus',
  data() {
    return {
      loading: true,
      waiver_status: 'Not Completed', // Qualified, Not Qualified, or Not Completed
      ineligible_reason: '',
      waiver_date: null,
      waiver_submitter: '',
      proposed_schedule: '01/01/2026 - 12/31/2031',
      waiver_id: null,
      receipt_uuid: null
    }
  },
  computed: {
    pws_id: function () {
      return this.$store.state.pws.pws_id
    },
    pws_name: function () {
      return this.$store.state.pws.pws_name
    },
    statusVariant: function () {
      switch (this.waiver_status) {
        case 'Qualified':
          return 'success'
        case 'Not Qualified':
          return 'danger'
        default:
          return 'warning'
      }
    }
  },
  methods: {
    formatDate(dateStr) {
      if (!dateStr) return ''
      const date = new Date(dateStr)
      // show time in Central Time
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'America/Chicago',
        timeZoneName: 'short'
      })
    },
    async fetchWaiverStatus() {
      this.loading = true
      try {
        // Replace with actual API endpoint
        const url = new URL(process.env.VUE_APP_API_URI + "/monitoring-waiver-status")
        url.searchParams.set('pws_id', this.pws_id)

        const response = await this.axios.get(url)
        const data = response.data

        console.log(data)

        // Set the status based on API response
        this.waiver_status = data.status || 'Not Completed'
        this.waiver_date = data.ts_created || null
        this.waiver_submitter = data.signame || ''
        this.ineligible_reason = data.reason || ''
        this.waiver_id = data.waiver_id || null
        this.receipt_uuid = data.receipt_uuid || null

        // For testing/demo purposes - remove this in production
        //if (process.env.NODE_ENV === 'development') {
        //  // Simulate API response for testing
        //  setTimeout(() => {
        //    // Randomly select a status for testing
        //    const statuses = ['Qualified', 'Not Qualified', 'Not Completed']
        //    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)]

        //    this.waiver_status = randomStatus
        //    if (randomStatus !== 'Not Completed') {
        //      this.waiver_date = new Date().toISOString()
        //      this.waiver_submitter = 'John Doe, Operator'
        //      this.waiver_id = 'test-waiver-' + Math.floor(Math.random() * 1000)
        //    }
        //    if (randomStatus === 'Not Qualified') {
        //      this.ineligible_reason = 'Surface water sources present in system'
        //    }

        //    this.loading = false
        //  }, 1000)
        //} else {
        //  this.loading = false
        //}
      } catch (error) {
        console.error('Error fetching waiver status:', error)
      } finally {
        this.loading = false
      }
    },
    printReceipt() {
      const frame = document.getElementById('receiptFrame')
      // Wait for iframe to load fully
      if (frame.contentWindow.document.readyState === 'complete') {
        frame.contentWindow.print();
      } else {
        frame.onload = function () {
          frame.contentWindow.print();
        };
      }
    }

  },
  mounted() {
    this.fetchWaiverStatus()
  }
}
</script>

<style scoped>
.status-icon {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
</style>
