<template>
  <div class="container mt-4 mb-5 receipt-container">
    <!-- Loading indicator -->
    <div v-if="loading" class="text-center my-5">
      <b-spinner label="Loading..."></b-spinner>
      <p class="mt-2">Loading waiver receipt...</p>
    </div>

    <!-- Error state -->
    <div v-else-if="error" class="alert alert-danger p-4">
      <h5><b-icon icon="exclamation-circle" class="mr-2"></b-icon> Error</h5>
      <p>Unable to load waiver receipt. The waiver ID may be invalid or no longer available.</p>
      <b-button variant="outline-primary" class="mt-2" to="/monitoring-waiver-status">
        Return to Waiver Status
      </b-button>
    </div>

    <!-- Receipt content -->
    <div v-else class="receipt">
      <div class="receipt-title mb-4">
        <div class="d-flex align-items-center justify-content-center">
          <img src="https://pws.mswater.us/static/msdh_logo.png" alt="MSDH Logo" class="msdh-logo mr-3" />
          <div class="text-center">
            <h3 class="mb-1">Susceptibility Monitoring Assessment Waiver Receipt</h3>
            <p class="text-muted">Mississippi State Department of Health</p>
          </div>
        </div>
      </div>

      <!-- Status indicator -->
      <div class="mb-4">
        <div :class="statusClass" class="p-3">
          <h5 class="mb-0">
            <b-icon :icon="statusIcon" class="mr-2"></b-icon>
            Status: {{ status }}
          </h5>
          <p class="mt-2 mb-0" v-if="status === 'Qualified'">
            Based on our records and information submitted here, this water system is eligible for a schedule reduction
            for
            the <b>Inorganic</b> analyte group as stated in
            <a href="https://www.ecfr.gov/current/title-40/chapter-I/subchapter-D/part-141/subpart-C/section-141.23"
              target="_blank">40 CFR 141.23</a>
          </p>
          <p class="mt-2 mb-0" v-else-if="status === 'Not Qualified'">
            Based on review of compliance records, this water system does not currently qualify for a monitoring
            schedule reduction.
          </p>
          <p class="mt-2 mb-0" v-else>
            This waiver application is currently under review. According to the results and/or previous compliance
            records,
            this water system may be eligible for a schedule reduction for the <b>Inorganic</b> analyte group as stated
            in
            <a href="https://www.ecfr.gov/current/title-40/chapter-I/subchapter-D/part-141/subpart-C/section-141.23"
              target="_blank">40 CFR 141.23</a>
          </p>
        </div>
      </div>

      <!-- Receipt header -->
      <div class="receipt-header mb-4 receipt-header-section">
        <div class="row">
          <div class="col-md-6">
            <p><strong>Water System:</strong> {{ pws_id }} - {{ pws_name }}</p>
          </div>
        </div>
        <div class="row header-details-row">
          <div class="col-md-6">
            <p><strong>Beginning Year:</strong> {{ waiver_year }}</p>
          </div>
          <div class="col-md-6">
            <p><strong>Waiver Schedule:</strong> {{ proposed_schedule }}</p>
          </div>
        </div>
      </div>

      <!-- Waiver details -->
      <div class="mb-4 well-info-section">
        <h5>Well Information</h5>
        <div v-if="wells && wells.length > 0" class="table-responsive">
          <table class="table table-sm well-table">
            <thead>
              <tr>
                <th>Well ID</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Susceptibility Rank</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="well in wells" :key="well.st_asgn_ident_cd">
                <td>{{ well.st_asgn_ident_cd }}</td>
                <td>{{ well.latitude_measure }}</td>
                <td>{{ well.longitude_measure }}</td>
                <td>{{ well.susc_rank }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p v-else class="font-italic">No wells included in this application.</p>
      </div>

      <div class="mb-4 source-info-section">
        <h5>Source Information</h5>
        <ul class="list-unstyled compact-list">
          <li><strong>Active sources not listed?</strong> {{ active_sources === 'yes' ? 'Yes' : 'No' }}</li>
          <li><strong>Surface water or groundwater under direct influence present?</strong> {{ surface_water === 'yes' ?
            'Yes' : 'No' }}</li>
          <li><strong>Industrial sources of cyanide present?</strong> {{ cyanide_sources === 'yes' ? 'Yes' : 'No' }}
          </li>
        </ul>
      </div>

      <div class="mb-4 treatment-info-section">
        <h5>Treatment Information</h5>
        <div v-if="no_treatment">
          <p>No treatment associated with contaminant removal.</p>
        </div>
        <div v-else-if="selected_treatments && selected_treatments.length > 0">
          <ul class="compact-list">
            <li v-for="code in selected_treatments" :key="code">
              {{ getTreatmentName(code) }} ({{ code }})
              <span v-if="code == '1025'"><i>- Removal Only</i></span>
            </li>
          </ul>
        </div>
        <div v-else>
          <p class="font-italic">No treatments selected.</p>
        </div>

        <div v-if="additional_info" class="mt-2">
          <p><strong>Additional Information:</strong></p>
          <p class="font-italic">{{ additional_info }}</p>
        </div>
      </div>

      <hr class="my-4">

      <!-- Certification -->
      <div class="mb-4 certification-section">
        <h5>Certification</h5>
        <p>The applicant certified that:</p>
        <ul class="compact-list">
          <li>The information provided on the waiver application and waiver matrix is correct and fully verified.</li>
          <li>The well(s) are currently installed as permitted and all components function as intended and are in good
            condition.</li>
        </ul>
      </div>

      <!-- Signature -->
      <div class="mb-4 signature-section">
        <h5>Signature Information</h5>
        <div class="row signature-row">
          <div class="col-md-4">
            <p><strong>Signed By:</strong> {{ signame }}</p>
          </div>
          <div class="col-md-4">
            <p><strong>Title/Role:</strong> {{ sigtitle }}</p>
          </div>
          <div class="col-md-4">
            <p><strong>Date/Time:</strong> {{ formatDate(data_signature_ts) }}</p>
          </div>
        </div>
        <p v-if="phone_number"><strong>Contact Phone:</strong> {{ phone_number }}</p>
      </div>

      <!-- Footer -->
      <div class="text-muted mt-5 pt-3 border-top">
        <div class="row">
          <div class="col-md-6">
            <small>This document serves as the official receipt for your monitoring waiver application.</small>
          </div>
          <div class="col-md-6 text-right">
            <b-button variant="outline-secondary" size="sm" @click="printReceipt()" class="print-button">
              <b-icon icon="printer"></b-icon> Print
            </b-button>
            <b-button variant="outline-primary" size="sm" to="/monitoring-waiver-status" class="ml-2 print-button">
              Back to Status
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonitoringWaiverReceipt',
  props: {
    waiver_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      error: false,

      // Waiver data
      pws_id: '',
      pws_name: '',
      waiver_year: '',
      proposed_schedule: '',
      wells: [],
      active_sources: 'no',
      surface_water: 'no',
      cyanide_sources: 'no',
      selected_treatments: [],
      no_treatment: false,
      additional_info: '',
      phone_number: '',
      confirmation_id: '',
      status: 'Pending', // Pending, Qualified, or Not Qualified

      // Signature data from API
      signed_data: null,
      data_signature: null,
      public_key: null,
      data_signature_ts: null,
      signame: '',
      sigtitle: '',

      // Treatment options lookup
      treatmentOptions: [
        { code: '1074', name: 'ANTIMONY, TOTAL' },
        { code: '1005', name: 'ARSENIC' },
        { code: '1010', name: 'BARIUM' },
        { code: '1075', name: 'BERYLLIUM, TOTAL' },
        { code: '1015', name: 'CADMIUM' },
        { code: '1020', name: 'CHROMIUM' },
        { code: '1025', name: 'FLUORIDE' },
        { code: '1035', name: 'MERCURY' },
        { code: '1045', name: 'SELENIUM' },
        { code: '1085', name: 'THALLIUM, TOTAL' }
      ]
    }
  },
  computed: {
    store_pws_id() {
      return this.$store.state.pws.pws_id
    },
    store_pws_name() {
      return this.$store.state.pws.pws_name
    },
    statusClass() {
      switch (this.status) {
        case 'Qualified':
          return 'alert alert-success'
        case 'Not Qualified':
          return 'alert alert-danger'
        default:
          return 'alert alert-warning'
      }
    },
    statusIcon() {
      switch (this.status) {
        case 'Qualified':
          return 'check-circle'
        case 'Not Qualified':
          return 'x-circle'
        default:
          return 'clock'
      }
    }
  },
  methods: {
    async fetchWaiverData() {
      this.loading = true;
      this.error = false;

      try {
        const url = new URL(process.env.VUE_APP_API_URI + "/monitoring-waivers/" + this.waiver_id);
        const response = await this.axios.get(url);
        console.log(response)
        const data = response.data;

        // Get signature data
        this.signed_data = data.signed_data;
        this.data_signature = data.data_signature;
        this.public_key = data.public_key;
        this.data_signature_ts = data.data_signature_ts;
        this.signame = data.signame;
        this.sigtitle = data.sigtitle;
        this.status = data.status || 'Pending';

        // If we have signed data, parse it
        if (this.signed_data) {
          try {
            const parsedData = JSON.parse(this.signed_data);

            // Populate receipt fields
            this.pws_id = parsedData.pws_id || this.store_pws_id;
            this.pws_name = parsedData.pws_name || this.store_pws_name;
            this.waiver_year = parsedData.waiver_year || '';
            this.proposed_schedule = parsedData.proposed_schedule || '';
            this.wells = parsedData.wells || [];
            this.active_sources = parsedData.active_sources || 'no';
            this.surface_water = parsedData.surface_water || 'no';
            this.cyanide_sources = parsedData.cyanide_sources || 'no';
            this.selected_treatments = parsedData.selected_treatments || [];
            this.no_treatment = parsedData.no_treatment || false;
            this.additional_info = parsedData.additional_info || '';
            this.phone_number = parsedData.phone_number || '';
            this.confirmation_id = data.confirmation_id || '';
          } catch (e) {
            console.error("Error parsing signed data:", e);
            // Use direct data instead
            this.populateFromDirectData(data);
          }
        } else {
          // No signed data, use direct data
          this.populateFromDirectData(data);
        }

        // For development/testing
        if (process.env.NODE_ENV === 'development' && !this.data_signature_ts) {
          this.simulateDemoData();
        }
      } catch (error) {
        console.error("Error fetching waiver data:", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    printReceipt() {
      window.print();
    },
    populateFromDirectData(data) {
      // Fallback to direct data fields if signed_data parsing fails
      this.pws_id = data.pws_id || this.store_pws_id;
      this.pws_name = data.pws_name || this.store_pws_name;
      this.waiver_year = data.waiver_year || '';
      this.proposed_schedule = data.proposed_schedule || '';
      this.wells = data.wells || [];
      this.active_sources = data.active_sources || 'no';
      this.surface_water = data.surface_water || 'no';
      this.cyanide_sources = data.cyanide_sources || 'no';
      this.selected_treatments = data.selected_treatments || [];
      this.no_treatment = data.no_treatment || false;
      this.additional_info = data.additional_info || '';
      this.phone_number = data.phone_number || '';
      this.confirmation_id = data.confirmation_id || '';
      this.status = data.status || 'Pending';
    },

    simulateDemoData() {
      // Only use this in development mode
      if (process.env.NODE_ENV !== 'development') return;

      // Fill in fake values for testing
      this.pws_id = this.pws_id || 'MS0000123';
      this.pws_name = this.pws_name || 'Demo Water System';
      this.waiver_year = this.waiver_year || '2026';
      this.proposed_schedule = this.proposed_schedule || '01/01/2026 - 12/31/2031';
      this.wells = this.wells.length ? this.wells : [
        { st_asgn_ident_cd: 'WELL-001', latitude_measure: '32.302082', longitude_measure: '-90.182106', susc_rank: 'Low' },
        { st_asgn_ident_cd: 'WELL-002', latitude_measure: '32.305624', longitude_measure: '-90.186217', susc_rank: 'Medium' }
      ];
      this.signame = this.signame || 'John Smith';
      this.sigtitle = this.sigtitle || 'Operator';
      this.phone_number = this.phone_number || '(601) 555-1234';
      this.data_signature_ts = this.data_signature_ts || new Date().toISOString();
      this.confirmation_id = this.confirmation_id || 'TEST-' + Math.floor(Math.random() * 10000);
      this.selected_treatments = this.selected_treatments.length ? this.selected_treatments : ['1025', '1035'];

      // Random status for testing
      if (!this.status || this.status === 'Pending') {
        const statuses = ['Pending', 'Qualified', 'Not Qualified'];
        this.status = statuses[Math.floor(Math.random() * statuses.length)];
      }
    },

    formatDate(dateStr) {
      if (!dateStr) return 'Unknown Date';
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },

    getTreatmentName(code) {
      const treatment = this.treatmentOptions.find(t => t.code === code);
      return treatment ? treatment.name : `Unknown (${code})`;
    }
  },
  mounted() {
    this.fetchWaiverData();
  }
}
</script>

<style scoped>
.receipt-container {
  max-width: 800px;
  margin: 0 auto;
}

.receipt {
  background-color: white;
  padding: 2rem;
  border: 1px solid #dee2e6;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.msdh-logo {
  height: 100px;
  width: auto;
}

@media print {
  .receipt-container {
    max-width: 100%;
  }

  .receipt {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .print-button {
    display: none !important;
  }

  .msdh-logo {
    height: 100px;
    /* Slightly smaller for print */
    print-color-adjust: exact;
    /* Ensures the image prints in color */
    -webkit-print-color-adjust: exact;
  }

  /* Prevent row breaking */
  .row {
    display: flex;
    flex-wrap: nowrap;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Keep columns in a row */
  .col-md-4,
  .col-md-6 {
    flex: 1;
    width: auto !important;
    max-width: none !important;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Keep tables together */
  .table-responsive {
    overflow: visible;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Keep table rows together */
  table {
    width: 100%;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  tr,
  th,
  td {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Adjust spacing for print */
  h5,
  p,
  ul,
  li {
    margin-bottom: 0.5rem !important;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  /* Compacting for more vertical efficiency */
  ul,
  li {
    margin-top: 0;
    padding-top: 0;
  }

  /* Keep signature section together */
  .signature-section {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .signature-row {
    display: flex;
    flex-wrap: nowrap;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Well information table styling for print */
  .well-info-section {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .well-table {
    font-size: 90%;
  }

  .well-table th,
  .well-table td {
    padding: 0.15rem 0.3rem !important;
  }

  /* Receipt header styling */
  .receipt-header-section {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .header-details-row {
    margin-top: 0.1rem !important;
  }

  /* Compact all spacing for print */
  p {
    margin-bottom: 0.2rem !important;
  }

  /* Source and treatment sections */
  .source-info-section,
  .treatment-info-section,
  .certification-section {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .compact-list {
    margin-bottom: 0.5rem;
  }

  .compact-list li {
    margin-bottom: 0.1rem;
    line-height: 1.3;
  }

  /* Make horizontal rule more compact */
  hr.my-4 {
    margin: 0.5rem 0 !important;
  }
}
</style>
