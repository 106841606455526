<template>
  <div class="container mt-4 mb-5">
    <b-card no-body class="border-0 shadow-sm">
      <b-card-header class="bg-primary text-white">
        <h4 class="mb-0">
          Susceptibility Monitoring Assessment + Waiver Application Groundwater Sources Only
        </h4>
      </b-card-header>

      <b-card-body>
        <!-- Introduction Section -->
        <b-card class="mb-4" border-variant="light">
          <h5 class="text-primary mb-3">Waiver Eligibility</h5>
          <p>
            According to the results and/or previous compliance records provided during previous monitoring periods
            this water system may be eligible for a schedule reduction for the <b>Inorganic</b> analyte group
            and Cyanide as stated in
            <a href="https://www.ecfr.gov/current/title-40/chapter-I/subchapter-D/part-141/subpart-C/section-141.23"
              target="_blank">40 CFR 141.23</a>
            Please review the following.
          </p>

          <!-- Contaminants Table -->
          <div v-if="false" class="table-responsive mb-3">
            <table class="table table-bordered table-sm">
              <thead class="bg-light">
                <tr>
                  <th>Contaminant</th>
                  <th class="text-center">Code</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in treatmentOptions" :key="item.code">
                  <td>{{ item.name }}</td>
                  <td class="text-center">{{ item.code }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Proposed Monitoring Schedule -->
          <h5 class="text-primary mb-3">Proposed Monitoring Schedule</h5>
          <div class="row mb-3 mt-3">
            <div class="col-md-12">
              <b-form-group label="Water System:" label-class="font-weight-bold">
                <b-form-input :value="pws_id + ' - ' + pws_name" readonly></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mb-3 mt-3">
            <div class="col-md-6">
              <b-form-group label="Beginning Year:" label-class="font-weight-bold">
                <b-form-input v-model="waiverYear" readonly></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Proposed Schedule:" label-class="font-weight-bold">
                <b-form-input v-model="proposedSchedule" readonly></b-form-input>
              </b-form-group>
            </div>
          </div>
          <p class="alert alert-info">
            Please review the following information to confirm waiver eligibility.
          </p>
        </b-card>

        <!-- Source Information Section -->
        <b-card class="mb-4" border-variant="light">
          <h5 class="text-primary mb-3">Source Information</h5>

          <!-- Wells Table -->
          <b-table :items="wellItems" :fields="wellFields" striped hover responsive class="mb-4"></b-table>

          <!-- Add Well Button -->
          <!--
          <div class="d-flex justify-content-end mb-3">
            <b-button variant="outline-primary" size="sm">
              <b-icon icon="plus"></b-icon> Add Well
            </b-button>
          </div>
          -->

          <!-- Empty state message when no wells -->
          <div v-if="wellItems.length === 0" class="text-center py-3 bg-light border rounded mb-3">
            <p class="text-muted mb-0">No wells have been added. Click "Add Well" to begin.</p>
          </div>

          <!-- Yes/No Questions -->
          <div class="mt-4">
            <table class="table table-bordered">
              <thead class="bg-light">
                <tr>
                  <th style="width: 80%">Question</th>
                  <th class="text-center">Response</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="align-middle">Are there any active sources not listed?</td>
                  <td class="align-middle">
                    <div class="d-flex justify-content-center">
                      <b-form-radio-group v-model="activeSourcesValue" :options="[
                        { text: 'Yes', value: 'yes' },
                        { text: 'No', value: 'no' }
                      ]" buttons button-variant="outline-primary" size="sm"
                        name="active-sources-buttons"></b-form-radio-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="align-middle">Is there surface water or groundwater under the direct influence of surface
                    water sources present?</td>
                  <td class="align-middle">
                    <div class="d-flex justify-content-center">
                      <b-form-radio-group v-model="surfaceWaterValue" :options="[
                        { text: 'Yes', value: 'yes' },
                        { text: 'No', value: 'no' }
                      ]" buttons button-variant="outline-primary" size="sm"
                        name="surface-water-buttons"></b-form-radio-group>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="align-middle">
                    <strong>*For Cyanide*</strong> Are there any industrial sources of cyanide that would contaminate a
                    sample from this system?
                    <div class="text-muted small">
                      (Industrial sources are chemical industries, wastewater treatment, organizations that use
                      pesticides, fumigants,
                      produce plastics, conduct mining, produce drugs and dyes.)
                    </div>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex justify-content-center">
                      <b-form-radio-group v-model="cyanideSourcesValue" :options="[
                        { text: 'Yes', value: 'yes' },
                        { text: 'No', value: 'no' }
                      ]" buttons button-variant="outline-primary" size="sm"
                        name="cyanide-sources-buttons"></b-form-radio-group>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>

        <!-- Compliance Data Information -->
        <b-card class="mb-4" border-variant="light">
          <h5 class="text-primary mb-3">Compliance Data Information</h5>

          <b-form-group label="Does your water system use treatment processes to remove any of these contaminants?">
            <p class="text-muted small mb-2">Check all that apply</p>
            <div class="treatment-options row">
              <div class="col-md-6">
                <b-form-checkbox v-for="item in treatmentOptions.slice(0, Math.ceil(treatmentOptions.length / 2))"
                  :key="item.code" v-model="selectedTreatments" :value="item.code" class="mb-2">
                  {{ item.name }} <span class="text-muted">({{ item.code }})</span>
                </b-form-checkbox>
              </div>
              <div class="col-md-6">
                <b-form-checkbox v-for="item in treatmentOptions.slice(Math.ceil(treatmentOptions.length / 2))"
                  :key="item.code" v-model="selectedTreatments" :value="item.code" class="mb-2">
                  {{ item.name }} <span class="text-muted">({{ item.code }})</span>
                  <!-- fluoride is removal only - bold -->
                  <span class="font-weight-bold" v-if="item.code == '1025'"> - Removal Only</span>
                </b-form-checkbox>
              </div>
            </div>
            <b-form-checkbox v-model="noTreatment" @change="handleNoTreatment" class="mt-2">
              There is no treatment associated with the removal of these contaminants.
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="If any information above is incorrect, please indicate here:">
            <b-form-textarea v-model="additionalInfo" rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
        </b-card>

        <!-- Certification Section -->
        <b-card border-variant="light">
          <h5 class="text-primary mb-3">Certification</h5>

          <b-form-group>
            <b-form-checkbox v-model="certifyForm" name="certification" class="mb-3">
              I certify that the above information provided on the waiver application and waiver matrix,
              to the best of my knowledge is correct, and fully verified.
            </b-form-checkbox>

            <b-form-checkbox v-model="certifyWells" name="certification-well" class="mb-4">
              I certify the well(s) are currently installed as permitted and that all components function
              as intended and are in good condition.
            </b-form-checkbox>
          </b-form-group>

          <div class="row">
            <div class="col-md-6 mb-3">
              <b-form-group label="Name of Application Preparer">
                <b-form-input v-model="preparer"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6 mb-3">
              <b-form-group label="Role">
                <b-form-select v-model="preparer_role" :options="roleOptions"></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-6 mb-3">
              <b-form-group label="Preferred Phone Number">
                <b-form-input v-model="phoneNumber" type="tel" :formatter="formatPhoneNumber" @input="validatePhone"
                  :state="phoneState" placeholder="(XXX) XXX-XXXX"></b-form-input>
              </b-form-group>
            </div>

          </div>

          <!-- Submit Buttons -->
          <div class="d-flex justify-content-between mt-4">
            <div>
              <b-button variant="outline-primary" to="/monitoring-waiver-status">
                <b-icon icon="arrow-left"></b-icon> Back to Status
              </b-button>
            </div>
            <div>
              <b-button variant="secondary" class="mr-2">Cancel</b-button>
              <b-button :disabled="!formComplete" @click="showSignSend" variant="primary">Submit Waiver</b-button>
            </div>
          </div>
        </b-card>
      </b-card-body>
    </b-card>
    <SignAndSend :submit_title="`Monitoring Waiver for ${pws_id}, ${pws_name}`" submit_url="monitoring-waivers"
      :submit_data="submit_data" :signer_name="preparer" :signer_role="preparer_role"
      @signing-success="onSigningSuccess" />

  </div>
</template>

<script>
import SignAndSend from "@/components/SignAndSend.vue"


export default {
  name: 'WaiverForm',
  components: {
    SignAndSend,
  },
  data() {
    return {
      submit_data: {},
      waiverYear: '2026',
      proposedSchedule: '01/01/2026 - 12/31/2031',
      phoneNumber: '',
      phoneState: null,
      additionalInfo: '',
      wellItems: [],
      wellFields: [
        { key: 'st_asgn_ident_cd', label: 'Well ID' },
        { key: 'latitude_measure', label: 'Latitude' },
        { key: 'longitude_measure', label: 'Longitude' },
        { key: 'susc_rank', label: 'Susceptibility Rank' },
      ],
      activeSourcesValue: 'no',
      surfaceWaterValue: 'no',
      cyanideSourcesValue: 'no',
      roleOptions: [
        { value: null, text: 'Select a role' },
        { value: 'Administrator', text: 'Administrator' },
        { value: 'Mayor', text: 'Mayor' },
        { value: 'Operator', text: 'Operator' },
        { value: 'Secretary', text: 'Secretary' }
      ],
      treatmentOptions: [
        { code: '1074', name: 'ANTIMONY, TOTAL' },
        { code: '1005', name: 'ARSENIC' },
        { code: '1010', name: 'BARIUM' },
        { code: '1075', name: 'BERYLLIUM, TOTAL' },
        { code: '1015', name: 'CADMIUM' },
        { code: '1020', name: 'CHROMIUM' },
        { code: '1025', name: 'FLUORIDE' },
        { code: '1035', name: 'MERCURY' },
        { code: '1045', name: 'SELENIUM' },
        { code: '1085', name: 'THALLIUM, TOTAL' }
      ],
      selectedTreatments: [], // This will contain the codes of selected treatments
      noTreatment: true,
      preparer: '',
      preparer_role: null,
      certifyWells: false,
      certifyForm: false,
    }
  },
  methods: {
    handleNoTreatment() {
      if (this.noTreatment) {
        // If "None" is checked, clear all treatments
        this.selectedTreatments = [];
      }
    },
    async getWellData() {
      console.log('getWellData')
      const url = new URL(process.env.VUE_APP_API_URI + "/mw-wells")
      url.searchParams.set('pws_id', this.pws_id)
      this.wellItems = []
      try {
        const response = await this.axios.get(url);
        console.log(response)
        this.wellItems = response.data.wells;
      } catch (error) {
        console.error(error);
      }
    },
    showSignSend() {
      this.submit_data.pws_id = this.pws_id
      this.submit_data.pws_name = this.pws_name
      this.submit_data.waiver_year = this.waiverYear
      this.submit_data.proposed_schedule = this.proposedSchedule
      this.submit_data.wells = this.wellItems
      this.submit_data.active_sources = this.activeSourcesValue
      this.submit_data.surface_water = this.surfaceWaterValue
      this.submit_data.cyanide_sources = this.cyanideSourcesValue
      this.submit_data.selected_treatments = this.selectedTreatments
      this.submit_data.no_treatment = this.noTreatment
      this.submit_data.waiver_date = this.waiver_date
      this.submit_data.certify_wells = this.certifyWells
      this.submit_data.certify_form = this.certifyForm
      this.submit_data.preparer = this.preparer
      this.submit_data.preparer_role = this.preparer_role
      this.submit_data.phone_number = this.phoneNumber
      this.submit_data.additional_info = this.additionalInfo
      // remove index_id we inserted
      this.$bvModal.show('sign-send')
    },
    onSigningSuccess(data) {
      // confirmation_id is the receipt ID
      console.log('onSigningSuccess', data)
      //this.$bvModal.hide('sign-send')
      this.$router.push({ name: 'MonitoringWaiverStatus' })
    },
    formatPhoneNumber(value) {
      // Remove all non-digits
      const digits = value.replace(/\D/g, '')

      // Format the phone number as (XXX) XXX-XXXX
      if (digits.length <= 3) {
        return digits
      } else if (digits.length <= 6) {
        return `(${digits.slice(0, 3)}) ${digits.slice(3)}`
      } else {
        return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
      }
    },
    validatePhone() {
      // Simple validation - checks if we have 10 digits
      const phoneDigits = this.phoneNumber.replace(/\D/g, '')
      // 10 or 0
      if (phoneDigits.length === 0) {
        this.phoneState = null
        return
      }
      this.phoneState = phoneDigits.length === 10 ? true : false
    }
  },
  computed: {
    pws_id: function () {
      return this.$store.state.pws.pws_id
    },
    pws_name: function () {
      return this.$store.state.pws.pws_name
    },
    formComplete: function () {
      return this.certifyForm && this.certifyWells && this.preparer && this.preparer_role && [null, true].includes(this.phoneState)
    }
  },
  mounted() {
    this.getWellData();
  },
  watch: {
    selectedTreatments(newVal) {
      // If any treatment is selected, uncheck "None"
      if (newVal.length > 0) {
        this.noTreatment = false;
      }
    }
  }
}
</script>

<style>
.required-field::after {
  content: " *";
  color: red;
}
</style>
